
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import KTNewTargetModal from "@/components/modals/forms/NewTargetModal.vue";
import { getCookie, removeCookie } from "typescript-cookie";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "comments-page",
  components: {
    KTNewTargetModal,
  },
  props: {
    Comments: Array,
  },
  setup(props) {
    const comment = ref();
    const Id = ref();
    const IntegrationId = ref();
    const filterMode = ref('');//All, OnlyPoints, OnlyComments
    const filterText = ref('');

    const filteredComments = computed(()=>{
      let filteredData = props.Comments;
      if(filterText.value !== "")
      {
        filteredData = filteredData?.filter((item:any)=>{
         
          return item.Comment.toLowerCase().includes(filterText.value.toLowerCase());
        })
      }
      if(filterMode.value != ''){
        filteredData = filteredData?.filter((item:any)=>{
          if(filterMode.value == "OnlyPoints"){
            return !item.Comment;
          }else if (filterMode.value == "OnlyComments"){
            return item.Comment;
          }
        })
      }
      return filteredData;
    });
    return {
      filteredComments,
      filterText,
      filterMode,
      comment,
      Id,
      IntegrationId,
      reinitializeComponents,
    };
  },
});
