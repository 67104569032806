
import { defineComponent, onMounted, onBeforeMount, ref } from "vue";
import CommentsWidget from "@/components/widgets/tables/CommentsWidget.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
const toast = useToast();

import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {
    CommentsWidget,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const Comments = ref(null);
    onBeforeMount(async () => {
      await store.dispatch(Actions.COMMENTS).then((response: any) => {
        console.log(response);
        Comments.value = response.data.comments;
        if (response.data.errors.length > 0) {
          response.data.errors.forEach((e) => {
            toast.error(e);
          });
        }
      });
    });

    onMounted(() => {
      setCurrentPageTitle("Yorumlar");
    });
    return {
      Comments,
    };
  },
});
